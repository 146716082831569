<template>
  <div class="content_box neirong">
    <div class="Reset">
      <van-icon name="warning" color="#4C8BF4" class="warning" />
      <p> 请在微信中打开！</p>
      
    </div>
  </div>
</template>

<script>
export default {
  name: "Reset",
  data() {
    return {

    }
  },
  created() {

  },
  mounted() {

  },
  methods: {


  },

  filters: {

  }
}
</script>

<style lang="scss" scoped>
.neirong{
  position: relative;
  // background-color: ;
}
.Reset{
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  color: #333;
  p{
    font-size: 18px;
    margin-top: 10px;
  }
  .warning{
    font-size: 28px;
  }
}
</style>